import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Cursor } from 'app/components/Common/Cursor'
import { Image } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  cta?: ButtonProps
  items: ItemProps[]
}

export const RoomsSlider = memo(function RoomsSlider({ cta, items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  const containerRef = useRef<any>()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1200,
    },
    drag: false,
    loop: items.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      items.length > 1 ? setDetails(s.track.details) : null
    },
  })

  const [thumbsSliderRef, thumbsRef] = useKeenSlider({
    defaultAnimation: {
      duration: 2400,
    },
    drag: false,
    loop: items.length > 1 ? true : false,
    slides: {
      origin: 'center',
      perView: 2.05,
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          perView: 1,
        },
      },
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    setLoaded(newLoaded)
  }, [currentSlide, galleryRef, sliderRef, thumbsRef, thumbsSliderRef])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Right>
          <CarouselContainer ref={containerRef}>
            <Carousel ref={sliderRef}>
              {uniq(items).map((item, index) =>
                item.image ? (
                  <Slide key={index} className="keen-slider__slide">
                    <Inner style={positionStyle(index)}>
                      <Image
                        alt={
                          loaded[index] || nextLoaded[index] ? item.title : ''
                        }
                        animationStart={
                          loaded[index] || nextLoaded[index] ? true : false
                        }
                        media="(min-width: 2800px)"
                        {...(loaded[index] || nextLoaded[index]
                          ? item.image
                          : null)}
                      />
                      <Spinner />
                    </Inner>
                  </Slide>
                ) : null,
              )}
            </Carousel>

            {cta ? (
              <InnerBottom>
                <FadeInUp>
                  <StyledButton {...cta} variant="light" />
                </FadeInUp>
              </InnerBottom>
            ) : null}

            {items.length > 2 ? (
              <Media lessThan="desktopSmall">
                <Arrows row space="between">
                  <Arrow
                    onClick={(e) =>
                      e.stopPropagation() ||
                      galleryRef.current?.prev() ||
                      thumbsRef.current?.prev()
                    }
                  />
                  <Arrow
                    direction="R"
                    onClick={(e) =>
                      e.stopPropagation() ||
                      galleryRef.current?.next() ||
                      thumbsRef.current?.next()
                    }
                  />
                </Arrows>
              </Media>
            ) : null}

            <Thumbs ref={thumbsSliderRef}>
              {uniq(items).map((item, index) =>
                item.title ? (
                  <Item
                    key={index}
                    className={`keen-slider__slide${
                      currentSlide === index ? ' active' : ''
                    }`}
                    title={item.title}
                  />
                ) : null,
              )}
            </Thumbs>

            {items.length > 1 ? (
              <Media greaterThanOrEqual="desktopSmall">
                <Cursor
                  container={containerRef}
                  gallery={galleryRef}
                  thumbs={thumbsRef}
                  hasOffset={true}
                />
              </Media>
            ) : null}
          </CarouselContainer>
        </Right>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin-left: 8.9375rem;

  @media (max-width: 1600px) {
    margin-left: 8.9375vw;
  }

  @media (max-width: 1199px) {
    margin-left: 0;
  }
`

const Right = styled.div`
  width: 100%;
  max-width: 91.0625rem;
  margin: 0 auto;
`

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;

  @media (min-width: 1200px) {
    &:hover {
      cursor: none;
    }
  }
`

const Carousel = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  max-height: 46.875rem;
  overflow: hidden;

  @media (max-width: 1199px) {
    max-height: 31.25rem;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    opacity: 0.3;
    z-index: 2;
  }
`

const Slide = styled.div`
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  right: 1.25rem;
  left: 1.25rem;
  transform: translateY(-50%);
  z-index: 3;
`

const Thumbs = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 100%;
  outline: none;
  overflow: visible;
  z-index: 2;
`

const InnerBottom = styled.div`
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;

  @media (max-width: 550px) {
    width: calc(100% - 2.5rem);
  }
`

const StyledButton = styled(Button)`
  @media (max-width: 550px) {
    max-width: 100%;
  }
`
